<template>
  <div class="text-right box">
    <!-- <div class="dflexs head">
            <img class="returns" @click='returnBtn' src="../assets/iconrenter.png" alt="">
            <div class="pagetitle">{{ $t('diamondDescription.title') }}</div>
        </div> -->
    <div class="text">
      <p>{{ $t("diamondDescription.txt1") }}</p>
      <p>{{ $t("diamondDescription.txt2") }}</p>
      <p>{{ $t("diamondDescription.txt3") }}</p>
      <p>{{ $t("diamondDescription.txt4") }}</p>
      <p>{{ $t("diamondDescription.txt5") }}</p>
      <p>{{ $t("diamondDescription.txt6") }}</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userInfo: [],
    };
  },
  created() {
    this.$completes.complete("getUserInfo");
    window.getUserInfo = this.getUserInfo
    window['getUserInfo'] = (data) => {
        this.getUserInfo(data);
    }
  },
  mounted() {},
  methods: {
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      let langs = this.userInfo.lang.substring(0, 2)
      let lang = this.userInfo.lang
      if (langs == "zh") {
        if (lang == "zh_TW" || lang == "zh_HK") {
          this.$i18n.locale = "tc";
        } else {
          this.$i18n.locale = "zh";
        }
      } else if (langs == "en") {
        this.$i18n.locale = "en";
      } else if (langs == "ar") {
        this.$i18n.locale = "ar";
      } else if (langs == "tr") {
        this.$i18n.locale = "tr";
      } else if (langs == "es") {
        this.$i18n.locale = "es";
      } else if (langs == "pt") {
        this.$i18n.locale = "pt";
      } else {
        this.$i18n.locale = "en";
      }
      document.title = this.$t("diamondDescription.title");
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
    },

            returnBtn(){
           this.$completes.complete("getPreviousPage");
        }
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}

.box {
  height: 100%;
  display: flex;
  flex-direction: column;
}
/* html[lang="ar"] .head {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
} */
html[lang="ar"] .text p {
  direction: rtl;
}

/* .head {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.27rem;
    margin: 0 !important;
    left: 0;
    right: 0;
    z-index: 999;
    top: 0;
    padding-top: 44px;
} */

/* .returns {
    width: 0.5rem;
    height: 0.5rem;
} */

/* .pagetitle {
    font-size: 0.3rem;
    font-family: Mediums;
    color: #000000;
    flex: 1;
    text-align: center;
    padding-right: 0.4rem;
} */
.text {
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  padding: 20px 18px;
}

.text p {
  line-height: 30px;
  margin-top: 20px;
}
</style>
